import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

const SearchResultsPage = ({ data, location }) => {
  function createMarkup(text) {
    return { __html: text }
  }

  const { siteMetadata } = data.site

  const metaDescription = `Search results for ${data.site.siteMetadata.fullPracticeName}`

  if (
    location.state &&
    location.state.suggestions &&
    location.state.searchInput &&
    location.state.searchTime
  ) {
    const results = location.state.suggestions
    const input = location.state.searchInput
    const { searchTime } = location.state
    const searchResults = Object.keys(results).map(key => {
      if (key !== "key") {
        // dangerouslySetInnerHTML is used here as work-around to render <sup> tags...
        const heading = createMarkup(
          results[key].searchHeading.replace(/[™®©]/g, "<sup>$&</sup>")
        )
        const paragraph = createMarkup(
          results[key].metaDescription.replace(/[™®©]/g, "<sup>$&</sup>")
        )
        let url = results[key].url || results[key].title
        // url = url.toLowerCase();
        if (url.charAt(0) !== "/") url = `/${url}`

        return (
          <div key={url} className="single-result">
            <Link to={url}>
              <h6
                className="modified-h6 mt-0"
                dangerouslySetInnerHTML={heading}
              />
            </Link>
            <p dangerouslySetInnerHTML={paragraph} />
            <Link to={url}>{`${siteMetadata.siteUrl}${url}`}</Link>
          </div>
        )
      }
    })

    const resultsLength = Object.keys(results).length

    return (
      <SharedStateProvider>
        <Layout language="en">
          <SEO
            title="Search Results"
            description={metaDescription}
            robots="noindex"
            lang="en"
          />
          <section className="com-content search-results-page">
            <div className="container">
              <div className="columns">
                <div className="column" />
                <div className="column is-20">
                  <h2>Search Results</h2>
                  <h6 className="mt-0">
                    {resultsLength} result{resultsLength === 1 ? "" : "s"} for "
                    {input}" ({searchTime} seconds)
                  </h6>
                  {resultsLength > 0 && (
                    <div className="search-results-wrapper">
                      {searchResults}
                    </div>
                  )}
                </div>
                <div className="column" />
              </div>
            </div>
          </section>
        </Layout>
      </SharedStateProvider>
    )
  }
  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          title="Search Results"
          description={metaDescription}
          robots="noindex"
        />
        <section className="com-content">
          <div className="container">
            <div className="columns">
              <div className="column" />
              <div className="column is-20">
                <h2>Search Results</h2>
                <h6>0 results found</h6>
              </div>
              <div className="column" />
            </div>
          </div>
        </section>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query searchQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        fullPracticeName
      }
    }
  }
`

export default SearchResultsPage
